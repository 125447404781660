import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { GlobalProvider } from "./src/context/GlobalContext";
import Layout from "./src/components/Layout";

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider reCaptchaKey="6Lf1aJYaAAAAADF3pkkadT6eypqWtz7C_esD4n6T">
    <GlobalProvider>{element}</GlobalProvider>
  </GoogleReCaptchaProvider>
);

import React, { useState } from "react";
import { Link } from "gatsby";

const GlobalContext = React.createContext();

export const themeConfigDefault = {
  bodyDark: false,
  headerDark: false,
  footerDark: false,
  headerStyle: "style1", //style2, style3
  headerFluid: true,
  headerButton: (
    <>
      <Link
        to="/contact"
        className="btn btn btn-blue-3 header-btn rounded-5 mr-2"
      >
        Contactar
      </Link>
      <a
        href="https://app.timeview.io"
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-outline-gray-1 gr-hover-bg-blue-3 header-btn rounded-5 heading-default-color border-width-2"
      >
        Acceder
      </a>
    </>
  ),
  headerClassName: "site-header--menu-right",
  headerSocial: false,
  headerLogoClassName: "",
  footerStyle: "style3",
  footerClassName: "",
};

const GlobalProvider = ({ children }) => {
  const [theme, setTheme] = useState(themeConfigDefault);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);

  const changeTheme = (themeConfig = themeConfigDefault) => {
    setTheme({
      ...theme,
      ...themeConfig,
    });
  };

  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible);
  };

  const toggleOffCanvas = () => {
    setVisibleOffCanvas(!visibleOffCanvas);
  };

  const closeOffCanvas = () => {
    setVisibleOffCanvas(false);
  };

  return (
    <GlobalContext.Provider
      value={{
        theme,
        changeTheme,
        videoModalVisible,
        toggleVideoModal,
        visibleOffCanvas,
        toggleOffCanvas,
        closeOffCanvas,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };

export const menuItems = [
  {
    name: "product",
    label: "Gestión del tiempo",
    items: [
      { name: "product/time", label: "Control de horarios" },
      { name: "product/app", label: "Fichar" },
      { name: "product/shifts", label: "Gestión de turnos" },
      { name: "product/vacations", label: "Vacaciones" },
      { name: "product/leaves", label: "Ausencias" },
      { name: "product/overtimes", label: "Horas extras" },
      { name: "product/swaps", label: "Permutas" },
      { name: "product/metrics", label: "Métricas" },
    ],
  },
  {
    name: "solutions",
    label: "Gestión de equipos",
    items: [
      { name: "solutions/crews", label: "Control de equipos" },
      { name: "solutions/requests", label: "Solicitudes" },
      { name: "solutions/incidences", label: "Incidencias" },
      { name: "solutions/equipment", label: "Equipamiento" },
      { name: "solutions/projects", label: "Proyectos" },
      { name: "solutions/telecommute", label: "Teletrabajo" },
    ],
  },
  {
    name: "tools",
    label: "Herramientas para empleados",
    items: [
      { name: "tools/employee", label: "Portal del empleado" },
      { name: "tools/communications", label: "Comunicación interna" },
      { name: "tools/documents", label: "Gestión documental" },
      { name: "tools/trips", label: "Viajes" },
      { name: "tools/expenses", label: "Gastos" },
      { name: "tools/notifications", label: "Notificaciones" },
    ],
  },
  {
    name: "pricing",
    label: "Precio",
  },
];

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kit-digital-js": () => import("./../../../src/pages/kit-digital.js" /* webpackChunkName: "component---src-pages-kit-digital-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-product-app-js": () => import("./../../../src/pages/product/app.js" /* webpackChunkName: "component---src-pages-product-app-js" */),
  "component---src-pages-product-leaves-js": () => import("./../../../src/pages/product/leaves.js" /* webpackChunkName: "component---src-pages-product-leaves-js" */),
  "component---src-pages-product-metrics-js": () => import("./../../../src/pages/product/metrics.js" /* webpackChunkName: "component---src-pages-product-metrics-js" */),
  "component---src-pages-product-overtimes-js": () => import("./../../../src/pages/product/overtimes.js" /* webpackChunkName: "component---src-pages-product-overtimes-js" */),
  "component---src-pages-product-shifts-js": () => import("./../../../src/pages/product/shifts.js" /* webpackChunkName: "component---src-pages-product-shifts-js" */),
  "component---src-pages-product-swaps-js": () => import("./../../../src/pages/product/swaps.js" /* webpackChunkName: "component---src-pages-product-swaps-js" */),
  "component---src-pages-product-time-js": () => import("./../../../src/pages/product/time.js" /* webpackChunkName: "component---src-pages-product-time-js" */),
  "component---src-pages-product-vacations-js": () => import("./../../../src/pages/product/vacations.js" /* webpackChunkName: "component---src-pages-product-vacations-js" */),
  "component---src-pages-solutions-crews-js": () => import("./../../../src/pages/solutions/crews.js" /* webpackChunkName: "component---src-pages-solutions-crews-js" */),
  "component---src-pages-solutions-equipment-js": () => import("./../../../src/pages/solutions/equipment.js" /* webpackChunkName: "component---src-pages-solutions-equipment-js" */),
  "component---src-pages-solutions-incidences-js": () => import("./../../../src/pages/solutions/incidences.js" /* webpackChunkName: "component---src-pages-solutions-incidences-js" */),
  "component---src-pages-solutions-projects-js": () => import("./../../../src/pages/solutions/projects.js" /* webpackChunkName: "component---src-pages-solutions-projects-js" */),
  "component---src-pages-solutions-requests-js": () => import("./../../../src/pages/solutions/requests.js" /* webpackChunkName: "component---src-pages-solutions-requests-js" */),
  "component---src-pages-solutions-telecommute-js": () => import("./../../../src/pages/solutions/telecommute.js" /* webpackChunkName: "component---src-pages-solutions-telecommute-js" */),
  "component---src-pages-tools-communications-js": () => import("./../../../src/pages/tools/communications.js" /* webpackChunkName: "component---src-pages-tools-communications-js" */),
  "component---src-pages-tools-documents-js": () => import("./../../../src/pages/tools/documents.js" /* webpackChunkName: "component---src-pages-tools-documents-js" */),
  "component---src-pages-tools-employee-js": () => import("./../../../src/pages/tools/employee.js" /* webpackChunkName: "component---src-pages-tools-employee-js" */),
  "component---src-pages-tools-expenses-js": () => import("./../../../src/pages/tools/expenses.js" /* webpackChunkName: "component---src-pages-tools-expenses-js" */),
  "component---src-pages-tools-notifications-js": () => import("./../../../src/pages/tools/notifications.js" /* webpackChunkName: "component---src-pages-tools-notifications-js" */),
  "component---src-pages-tools-trips-js": () => import("./../../../src/pages/tools/trips.js" /* webpackChunkName: "component---src-pages-tools-trips-js" */)
}


import React from "react";
import { Link } from "gatsby";

import imgLB from "../../assets/image/logo-main-black.svg";
import imgLW from "../../assets/image/logo-main-white.svg";
import imgFS from "../../assets/image/inner-page/png/yellow-footer-shape.png";

const Footer = () => {
  return (
    <div className="bg-dark-cloud pt-8 pt-lg-20 pb-7 dark-mode-texts position-relative">
      <div className="container">
        <div className="row pb-lg-18">
          <div className="col-lg-4 col-md-4 col-xs-4">
            <div className="pr-xl-20 mb-11 mb-lg-0 mt-lg-5">
              <div className="brand-logo mb-5">
                <Link to="/">
                  <img
                    className="mx-auto mx-0 light-version-logo default-logo"
                    src={imgLB}
                    alt=""
                  />
                  <img
                    src={imgLW}
                    alt=""
                    className="dark-version-logo mx-auto mx-0"
                  />
                </Link>
              </div>
              <p className="font-size-5 mb-0 text-bali-gray pr-sm-10 pr-md-0">
                Tu solución final de RRHH para el control horario y teletrabajo
                que te ayudará a tener un mayor control de tu empresa y
                empleados.
              </p>
              <div className="mt-8">
                <a
                  href="mailto:hola@timeview.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-blue-3 btn-sm rounded-5 text-lily-white font-size-3"
                >
                  ¡Pregúntanos!
                </a>
              </div>
            </div>
          </div>
          {/* Single Widgets */}
          <div className="col-lg-4 col-md-4 col-xs-4">
            <div className="mb-10 mb-lg-0 mr-xl-12">
              <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                Contacto
              </h4>
              <p className="font-size-5 mb-0 text-lily-white">
                Comúnicate con nosotros enviándonos un email o por teléfono.
              </p>
              <div className="mt-7">
                <a
                  className="font-size-5 d-block text-golden-yellow mb-0"
                  href="tel:+34 617 057 387"
                >
                  +34 617 057 387
                </a>
                <a
                  className="font-size-5 d-block text-golden-yellow mb-0"
                  href="mailto:hola@timeview.io"
                >
                  hola@timeview.io
                </a>
              </div>
            </div>
          </div>
          {/* End Single Widgets */}
          {/* Single Widgets */}
          <div className="col-lg-4 col-md-4 col-xs-4">
            <div className="mb-10 mb-lg-0 mr-xl-12">
              <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                Dónde estamos
              </h4>
              <p className="font-size-6 font-weight-bold mb-0 text-buttercup">
                Las Palmas
              </p>
              <p className="font-size-5 mb-4 text-lily-white">
                C/ Camelia, 15 – 17
              </p>
              <p className="font-size-6 font-weight-bold mb-0 text-buttercup">
                Tenerife
              </p>
              <p className="font-size-5 mb-4 text-lily-white">
                C/ Villalba Hervás, nº 5, 2º
              </p>
              <p className="font-size-6 font-weight-bold mb-0 text-buttercup">
                Madrid
              </p>
              <p className="font-size-5 font-weight-bold mb-0 text-lily-white">
                C/ De la Princesa, nº 31
              </p>
            </div>
          </div>
          {/* End Single Widgets */}
        </div>
        <div className="row pb-lg-12">
          <div className="col-lg-12 col-md-12">
            <div className="row">
              {/* Single Widgets */}
              <div className="col-lg-4 col-md-4 col-xs-4">
                <div className="mb-10 mb-lg-0">
                  <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                    Gestión del tiempo
                  </h4>
                  <ul className="list-unstyled">
                    <li className="mb-3">
                      <Link
                        to="/product/time"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Control de horarios
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="/product/app"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Fichar
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="/product/shifts"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Gestión de turnos
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="/product/vacations"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Vacaciones
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="/product/leaves"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Ausencias
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="/product/overtimes"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Horas extras
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="/product/swaps"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Permutas
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="/product/metrics"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Métricas
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Widgets */}
              {/* Single Widgets */}
              <div className="col-lg-4 col-md-4 col-xs-4">
                <div className="mb-10 mb-lg-0">
                  <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                    Gestión de equipos
                  </h4>
                  <ul className="list-unstyled">
                    <li className="mb-3">
                      <Link
                        to="/solutions/crews"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Control de equipos
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="/solutions/requests"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Solicitudes
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="/solutions/incidences"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Incidencias
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="/solutions/equipment"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Equipamiento
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="/solutions/projects"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Proyectos
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="/solutions/telecommute"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Teletrabajo
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Widgets */}
              {/* Single Widgets */}
              <div className="col-lg-4 col-md-4 col-xs-4">
                <div className="mb-10 mb-lg-0">
                  <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                    Herramientas para empleados
                  </h4>
                  <ul className="list-unstyled">
                    <li className="mb-3">
                      <Link
                        to="/tools/employee"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Portal del empleado
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="/tools/communications"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Comunicación interna
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="/tools/documents"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Gestión documental
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="/tools/trips"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Viajes
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="/tools/expenses"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Gastos
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="/tools/notifications"
                        className="font-size-5 text-lily-white gr-hover-text-blue-3"
                      >
                        Notificaciones
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Widgets */}
            </div>
          </div>
        </div>
        <div className="row align-items-center pt-8 border-top">
          <div className="col-lg-8 text-center text-lg-left">
            <div className="copyright">
              <p className="mb-0 font-size-3 text-bali-gray">
                © {new Date().getFullYear()} Timeview - Desarrollado por
                personas, para personas en DESIC
                <Link
                  to="/privacy"
                  className="text-bali-gray gr-hover-text-buttercup ml-7"
                >
                  Política de privacidad
                </Link>
                <Link
                  to="/legal"
                  className="text-bali-gray gr-hover-text-buttercup ml-7"
                >
                  Aviso legal
                </Link>
              </p>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-right">
            <div className="footer-right mt-5 mt-lg-0">
              <ul className="list-unstyled d-flex align-items-center justify-content-center justify-content-lg-end mb-0">
                <li>
                  <Link
                    to="https://www.instagram.com/timeview_hr/"
                    className="text-white gr-hover-text-buttercup ml-7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.youtube.com/@timeviewhr"
                    className="text-white gr-hover-text-buttercup ml-7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-youtube" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.linkedin.com/company/timeview-hr/"
                    className="text-white gr-hover-text-buttercup ml-7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-yellow-shape-img">
        <img src={imgFS} alt="" />
      </div>
    </div>
  );
};

export default Footer;
